import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

import styles from './gallery-item.module.scss';

const GalleryItem = ({ imageData, title, text, link }) => (
  <div className={styles.item}>
    <Link to={link}>
      <Img fluid={imageData} />
      <h2>{title}</h2>
      <p className={styles.content}>{text}</p>
    </Link>
  </div>
);

export default GalleryItem;
