import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';

import styles from './newsletter-box.module.scss';

import NewsletterForm from '../newsletter-form/newsletter-form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const NewsletterBox = () => {
  const { t } = useTranslation('newsletter');

  return (
    <div className={styles.root}>
      <Container>
        <Row>
          <Col md={{ offset: 3, span: 6 }}>
            <h2 className={styles.title}>{t('title')}</h2>
            <p className={styles.subTitle}>{t('subTitle')}</p>
            <NewsletterForm />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NewsletterBox;
