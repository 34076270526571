import React from 'react';
import styles from './press-banner.module.scss';
import { useTranslation } from 'react-i18next';
import TinySlider from 'tiny-slider-react';

import DerStandardLogo from './assets/der-standard-logo.png';
import FalstaffLogo from './assets/falstaff-logo.svg';
import PrestigeLogo from './assets/prestige-logo.svg';

const settings = {
  controls: false,
  nav: false,
  autoplayButtonOutput: false,
  loop: true,
  responsive: {
    0: {
      items: 1,
      autoplay: true,
      autoplayTimeout: '2500',
      speed: '2000',
      rewind: false,
    },
    576: {
      items: 3,
      autoplay: false,
    },
  },
};

const PressBanner = () => {
  const { t } = useTranslation('pressbanner');

  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <div className={styles.title}>{t('title')}</div>
        <TinySlider settings={settings}>
          <img src={DerStandardLogo} alt="Der Standard Logo" />
          <img src={FalstaffLogo} alt="Falstaff Logo" />
          <img src={PrestigeLogo} alt="Prestige Logo" />
        </TinySlider>
      </div>
    </div>
  );
};

export default PressBanner;
