import React from 'react';
import TinySlider from 'tiny-slider-react';

import styles from './text-banner.module.scss';

const settings = {
  controls: false,
  nav: false,
  autoplayButtonOutput: false,
  loop: true,
  responsive: {
    0: {
      items: 1,
      autoplay: true,
      autoplayTimeout: '2500',
      speed: '2000',
      rewind: false,
    },
    576: {
      items: 2,
    },
    768: {
      items: 3,
    },
    992: {
      items: 4,
    },
    1200: {
      items: 6,
      autoplay: false,
    },
  },
};

const TextBanner = () => {
  return (
    <div className={styles.banner}>
      <div className={styles.container}>
        <TinySlider settings={settings}>
          <h2 className={styles.text}>MANUAL FLOW-PROFILING</h2>
          <h2 className={styles.text}>MULTI-FLOW HEATER SYSTEM</h2>
          <h2 className={styles.text}>ADVANCED ENERGY-EFFICIENCY</h2>
          <h2 className={styles.text}>INNOVATIVE WATER-HEATING TECHNOLOGY</h2>
          <h2 className={styles.text}>SWISS PRECISION ENGINEERING</h2>
          <h2 className={styles.text}>INCOMPARABLE DESIGN</h2>
        </TinySlider>
      </div>
    </div>
  );
};

export default TextBanner;
