import React from 'react';
import styles from './hero-video.module.scss';
import heroVideo from '../../videos/manument.mp4';

const HeroVideoComponent = () => (
  <div className={styles.container}>
    <video className={styles.video} autoPlay loop muted playsInline>
      <source src={heroVideo} type="video/mp4" />
    </video>
  </div>
);

export default HeroVideoComponent;
