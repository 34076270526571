import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Gallery = ({ children }) => (
  <Container>
    <Row>
      {React.Children.map(children, (child) => (
        <Col md={4}>{child}</Col>
      ))}
    </Row>
  </Container>
);

export default Gallery;
