import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import FormText from 'react-bootstrap/FormText';
import FormGroup from 'react-bootstrap/FormGroup';
import FormControl from 'react-bootstrap/FormControl';

import useForm from '../../hooks/useForm';

const Form = ({ namespace }) => {
  const { t } = useTranslation(namespace);
  const { state, setFieldValue, handleSubmit } = useForm(namespace, namespace);

  if (state.submitted) {
    return <p className="text-muted">{t('form.successMessage')}</p>;
  }

  return (
    <form onSubmit={handleSubmit} noValidate>
      <FormGroup className="mb-4">
        <FormControl
          placeholder={t('form.firstName.placeholderText')}
          name="firstName"
          type="text"
          onChange={setFieldValue}
        />
        {state.errors.firstName && (
          <FormText className="text-danger">{state.errors.firstName}</FormText>
        )}
      </FormGroup>
      <FormGroup className="mb-4">
        <FormControl
          placeholder={t('form.lastName.placeholderText')}
          name="lastName"
          type="text"
          onChange={setFieldValue}
        />
        {state.errors.lastName && (
          <FormText className="text-danger">{state.errors.lastName}</FormText>
        )}
      </FormGroup>
      <FormGroup>
        <FormControl
          placeholder={t('form.email.placeholderText')}
          name="email"
          type="email"
          onChange={setFieldValue}
        />
        {state.errors.email && (
          <FormText className="text-danger">{state.errors.email}</FormText>
        )}
      </FormGroup>
      <FormGroup className="mb-0">
        <Button type="submit" variant="outline-red" disabled={state.loading}>
          {t('form.buttonText')}
        </Button>
        {state.loading && <FormText>{t('form.loadingMessage')}</FormText>}
        {state.errors.form && (
          <FormText className="text-danger">{state.errors.form}</FormText>
        )}
      </FormGroup>
    </form>
  );
};

export default Form;
