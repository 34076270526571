import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useTranslation } from 'react-i18next';

import Gallery from '../../components/gallery/gallery';
import GalleryItem from '../../components/gallery-item/gallery-item';

const query = graphql`
  {
    images: allFile(
      filter: { relativePath: { glob: "startseite/*" } }
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 759, quality: 80) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`;

const IndexGallery = () => {
  const data = useStaticQuery(query);
  const { t } = useTranslation('home');

  return (
    <Gallery>
      <GalleryItem
        imageData={data.images.edges[0].node.childImageSharp.fluid}
        title={t('gallery.technology.title')}
        text={t('gallery.technology.content')}
        link={t('gallery.technology.link')}
      />
      <GalleryItem
        imageData={data.images.edges[1].node.childImageSharp.fluid}
        title={t('gallery.about.title')}
        text={t('gallery.about.content')}
        link={t('gallery.about.link')}
      />
      <GalleryItem
        imageData={data.images.edges[3].node.childImageSharp.fluid}
        title={t('gallery.howto.title')}
        text={t('gallery.howto.content')}
        link={t('gallery.howto.link')}
      />
    </Gallery>
  );
};

export default IndexGallery;
