import { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import isEmail from 'is-email';

const initialState = {
  submitted: false,
  loading: false,
  values: {
    email: '',
    firstName: '',
    lastName: '',
  },
  errors: {
    form: null,
    email: null,
    firstName: null,
    lastName: null,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'resetErrors':
      return { ...state, errors: initialState.errors };
    case 'setError':
      return {
        ...state,
        errors: { ...state.errors, [action.location]: action.message },
      };
    case 'setLoading':
      return { ...state, loading: action.payload };
    case 'setSubmitted':
      return { ...state, submitted: action.payload };
    case 'setValue':
      return {
        ...state,
        values: { ...state.values, [action.field]: action.value },
      };
    default:
      throw new Error();
  }
};

const useForm = (type, i18nNamespace) => {
  const { t } = useTranslation(i18nNamespace);
  const [state, dispatch] = useReducer(reducer, initialState);

  const resetErrors = () => dispatch({ type: 'resetErrors' });

  const setError = (location, message) =>
    dispatch({
      type: 'setError',
      location,
      message,
    });

  const setLoading = (isLoading) =>
    dispatch({ type: 'setLoading', payload: isLoading });

  const setSubmitted = (isSubmitted) =>
    dispatch({ type: 'setSubmitted', payload: isSubmitted });

  const setFieldValue = (e) => {
    setError(e.target.name, null);
    dispatch({ type: 'setValue', field: e.target.name, value: e.target.value });
  };

  const validateForm = () => {
    let isValid = true;

    resetErrors();

    if (!state.values.email || !isEmail(state.values.email)) {
      setError('email', t('form.email.errorMessage'));
      isValid = false;
    }

    if (!state.values.firstName) {
      setError('firstName', t('form.firstName.errorMessage'));
      isValid = false;
    }

    if (!state.values.lastName) {
      setError('lastName', t('form.lastName.errorMessage'));
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true);

      try {
        const response = await fetch('/api/subscribe', {
          method: 'POST',
          body: JSON.stringify({
            ...state.values,
            type,
          }),
        });

        if (response.ok) {
          setSubmitted(true);
        } else {
          const body = await response.text();
          setError('form', t(`form.mailchimpErrors.${body}`));
        }
      } catch (e) {
        console.log(e);
      }

      setLoading(false);
    }
  };

  return {
    state,
    resetErrors,
    setError,
    setLoading,
    setSubmitted,
    setFieldValue,
    validateForm,
    handleSubmit,
  };
};

export default useForm;
