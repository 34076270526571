import React from 'react';
import { useTranslation } from 'react-i18next';

import SEO from '../components/seo';
import IndexGallery from '../content/de/index-gallery';
import PageWrapper from '../components/page-wrapper/page-wrapper';
import NewsletterBox from '../components/newsletter-box/newsletter-box';
import HeroVideo from '../components/hero-video/hero-video';
import TextBanner from '../components/text-banner/text-banner';
import { HeroState } from '../components/hero/hero-context';
import PressBanner from '../components/press-banner/press-banner';

const IndexPage = () => {
  const { t } = useTranslation('home');
  return (
    <PageWrapper padding>
      <SEO title={t('header.title')} />
      <HeroState clear />
      <HeroVideo />
      <TextBanner />
      <IndexGallery />
      <NewsletterBox />
      <PressBanner />
    </PageWrapper>
  );
};

export default IndexPage;
